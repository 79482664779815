import React, { useState, useEffect } from 'react';
import Main from '../Main';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { CheckboxWithName } from '../RenderFields/RenderFields';
import { languages } from '../../Constants';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { Spinner } from '@danfoss/webex-ui';
import { ListItem, ListItemData, UndoChangesProps, UndoLanguage } from './types';
import UndoChangesRow from './UndoChangesRow';
import { useSelector } from 'react-redux';
import { AppState } from '../../Store';


const UndoChanges: React.FC<InjectedFormProps<any, UndoChangesProps> & UndoChangesProps> = ({ breadCrumbs, typeName, handleSubmitClick, listItem }) => {
    const locationAPICountries = useSelector((state: AppState) => state.location.countries);
    const classes = useStyles();
    const [selectedItems, setSelectedItems] = useState([] as string[]);
    const [selections, setSelections] = useState([] as UndoLanguage[]);
    const [isUndoing, setIsUndoing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [detailsVisible, setDetailsVisible] = useState([] as string[]);
    const [itemData, setItemData] = useState([] as ListItemData[]);

    useEffect(() => {
        let selectionsToAdd = [] as UndoLanguage[]; //selections;

        setSelectedItems([listItem.id]);

        listItem.data.forEach(item => {
            selectionsToAdd.push({ language: `${listItem.id}_${item.language}_${item.country || ''}` });
        });

        setItemData(listItem.data)
        setSelections(selectionsToAdd);
        setIsLoading(false);

    }, []);

    const handleCheckboxClick = (target: any) => {

        if (target.checked) {
            if (!selections.find((item: UndoLanguage) => item.language === target.id)) {
                setSelections([...selections, { language: target.id }]);
            }

            if (!selectedItems.find((item: string) => item === target.id.substring(0, target.id.indexOf("_")))) {
                setSelectedItems([...selectedItems, target.id.substring(0, target.id.indexOf("_"))]);
            }

        } else {
            setSelections(selections.filter(item => item.language !== target.id));
        }
    }

    const handleSubmitButtonClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const deleteChanges = async () => {

            await handleSubmitClick(selections, setIsUndoing).then((resp: any) => {
                const newData = itemData.reduce((dataItems: ListItemData[], data) => {

                    const keys = [] as string[]
                    selections.map(selection => keys.push(selection.language));

                    if (keys.indexOf(`${listItem.id}_${data.language}_${data.country || ''}`) === -1) {
                        dataItems.push(data);
                    }

                    return dataItems;
                }, [])

                setItemData(newData)
                setSelections([]);
            });
        }

        deleteChanges();
    }

    const onChangeSelectAll = (checked: boolean) => {
        if (checked) {

            setSelectedItems([listItem.id]);

            onChangeSelectAllLanguages(listItem.id, checked);

        } else {
            setSelections([]);
            setSelectedItems([]);
        }
    }

    const toggleDetails = (id: string) => {

        if (!detailsVisible.includes(id)) {
            setDetailsVisible([...detailsVisible, id]);
        } else {
            setDetailsVisible(detailsVisible.filter((variant: string) => variant !== id));
        }

        return;
    }

    const onChangeSelectItem = (id: string, checked: boolean) => {

        if (!checked && selectedItems.indexOf(id) > -1) {
            setSelectedItems(selectedItems.filter(row => row !== id));
        }
        else {
            checked && setSelectedItems([...selectedItems, id]);
        }

        onChangeSelectAllLanguages(listItem.id, checked);
    }

    const onChangeSelectAllLanguages = (id: string, checked: boolean) => {

        if (checked) {

            let selectionsToAdd = selections;

            itemData.forEach(item => {

                const selection = { language: `${id}_${item.language}_${item.country || ''}` };

                if (!selections.find((item: UndoLanguage) => item.language === selection.language)) {
                    selectionsToAdd.push(selection)
                    setSelections([...selectionsToAdd]);
                }
            })

            if (!selectedItems.find((item: string) => item === listItem.id)) {
                setSelectedItems([...selectedItems, listItem.id]);
            }

        } else {
            setSelections(selections.filter(selection => !selection.language.startsWith(`${id}_`)));
        }

    }

    return (
        <Main breadCrumbs={breadCrumbs}>
            <Grid container className={classes.formContainer}>
                {(isLoading || isUndoing) ? (<Spinner visible={isLoading} />) : (
                    <form id="form_undoChanges" className={classes.translationForm}>
                        <Grid container className={classes.listGridHeading}>
                            <Grid item className={`${classes.listGridItem} ${classes.listGridSelect}`} >
                                <Field
                                    name="selectAll"
                                    id="selectAll"
                                    value={`${typeName} name`}
                                    defaultValue={`${typeName} name`}
                                    checked={(selectedItems.length > 0 && selectedItems.some(id => id === listItem.id))}
                                    component={CheckboxWithName}
                                    onClick={(event: any) => { onChangeSelectAll(event.target.checked); }}
                                    label=""
                                />
                            </Grid>
                            <Grid item className={`${classes.listGridItem} ${classes.listGridStatus}`} >
                                Status
                            </Grid>
                        </Grid>
                        {itemData.length > 0 && (
                            <Grid key={listItem.id} container>
                                <Grid key={listItem.id} container className={`${classes.listGrid}`} >
                                    <Grid item className={`${classes.listGridItem} ${classes.listGridSelect}`} >
                                        <Field
                                            name={`undoChanges${listItem.id}`}
                                            id={`undoChanges${listItem.id}`}
                                            value={listItem.name}
                                            defaultValue={listItem.name}
                                            checked={selectedItems.some(selApp => selApp === listItem.id)}
                                            component={CheckboxWithName}
                                            onClick={(event: any) => { onChangeSelectItem(listItem.id, event.target.checked); }}
                                            label=""
                                            className={classes.checkboxLabel}
                                        />
                                    </Grid>
                                    <Grid item className={`${classes.listGridItem} ${classes.listGridStatus}`} onClick={() => toggleDetails(listItem.id)}>
                                        {itemData.length > 0 ? "Under change" : "Published"}
                                    </Grid>
                                    <Grid item className={`${classes.listGridItem} ${classes.listGridIcon}`} onClick={() => toggleDetails(listItem.id)}>
                                        <img src={'/images/icons/ChevronDown.svg'} className={detailsVisible.includes(listItem.id) ? classes.iconStyleRotate : classes.iconStyle} alt="" />
                                    </Grid>
                                </Grid>

                                <Grid container className={detailsVisible.includes(listItem.id) ? classes.show : classes.hide}>
                                    <Grid container className={classes.subListGridHeading}>
                                        <Grid item className={`${classes.listGridItem} ${classes.listGridSelectLanguage}`} >
                                            <Field
                                                name="selectAll"
                                                id="selectAll"
                                                value={listItem.type === 'web' ? "Country" : "Language"}
                                                defaultValue={listItem.type === 'web' ? "Country" : "Language"}
                                                checked={(itemData.every(item => selections.some(sel => sel.language === `${listItem.id}_${item.language}_${item.country || ''}`)))}
                                                component={CheckboxWithName}
                                                onClick={(event: any) => { onChangeSelectAllLanguages(listItem.id, event.target.checked); }}
                                                label=""
                                            />
                                        </Grid>
                                        {listItem.itemHeaders.map(itemHeader =>
                                            <Grid key={itemHeader} item className={`${classes.listGridItem} ${classes.listGridHeader}`} >
                                                {itemHeader}
                                            </Grid>
                                        )}

                                    </Grid>
                                    {itemData.map((lid, index) => {
                                        const language = languages.find(lan => lan.id === lid.language);
                                        const country = lid.country ? locationAPICountries?.find(country => country.alpha2 === lid.country)?.name || '' : '';

                                        const isChecked = selections.some(appLan => appLan.language === `${listItem.id}_${lid.language}_${lid.country || ''}`) || false;
                                        const useGrayBackground = index % 2 === 0 ? false : true;

                                        return (
                                            <UndoChangesRow useGrayBackground={useGrayBackground} onChange={handleCheckboxClick} listItem={listItem || {} as ListItem} item={lid} language={language && language.text || ''} country={country} key={`${index}_${lid.language}_${lid.country || ''}`} checked={isChecked} />
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        )}


                        {selectedItems.length > 0 && selections.length > 0 && (
                            <Grid container className={classes.buttonContainer}>
                                <SpinnerButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    pathToImagesFolder={'/images/icons'}
                                    onClick={(e: any) => handleSubmitButtonClick(e)}
                                    spinnerVisible={isUndoing}
                                >
                                    Undo changes
                                </SpinnerButton>
                            </Grid>
                        )}
                        {!isLoading && selections.length === 0 &&
                            <Grid container >
                                <Grid item className={`${classes.listGridItem}`} >
                                    No changes to undo or no item rows selected
                                </Grid>
                            </Grid>
                        }
                    </form>
                )}
            </Grid>
        </Main>
    );
}

export default reduxForm<any, UndoChangesProps>({ form: "form_undoChanges" })(UndoChanges);
