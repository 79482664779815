import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import Routes  from './Components/Routes';
import settings from './Config/settings';
import { Auth0Provider } from "@auth0/auth0-react";
import configureStore from './Store/configureStore';

const store = configureStore();

ReactDOM.render(
    <ReduxProvider store={store} >
      <Auth0Provider
        domain={settings.auth0.custom_domain}
        clientId={settings.auth0.client_id}
        redirectUri={window.location.origin + '/login'}
        useRefreshTokens={true}
        connection={settings.auth0.connection}
      >
          <Routes />
      </Auth0Provider>
    </ReduxProvider>  
  ,
  document.getElementById('my-danfoss-maintenance-dashboard')
);

