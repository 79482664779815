import fetchApiWrapper from './fetchAPIWrapper';
import settings from '../Config/settings';
import { Application } from './application';
import { ReferenceItemApiModel } from './references';
export interface UserInfo {
    danfoss_identity_id: string,
    email: string,
    name: string,
    phone_number: string
}
export interface IdentificationModel {
    danfoss_identity_id: string,
    danfoss_user_id: string,
    is_workforce: boolean
}

export interface PreviewProfileIdentificationModel {
    is_workforce: boolean
}

export interface UserModel {
    email?: string,
    language?: ReferenceData,
    department?: ReferenceData,
    area_of_interest?: ReferenceData,
    areas_of_interest?: ReferenceData[],
    company_type?: ReferenceData,
    country?: ReferenceData,
    identification: IdentificationModel
}

interface ReferenceData {
    id: string,
    text: string
}

export interface UserApplications {
    user_applications: Application[]
}

export interface RecommendedApplications {
    client_ids: string[]
}

export interface PreviewRecommendedApplication {
    client_id: string,
    published: boolean,
    status?: string
}
export interface PreviewProfileApplication {
    app_id: string,
    name: string
}
export interface PreviewProfileAPI {
    user_profiles: PreviewProfileModel[]
}
export interface PreviewProfileModel {
    danfoss_identity_id: string,
    profile_name: string,
    language?: ReferenceItemApiModel,
    country?: ReferenceItemApiModel,
    area_of_interest?: ReferenceItemApiModel,
    areas_of_interest?: ReferenceItemApiModel[],
    company_type?: ReferenceItemApiModel,
    department?: ReferenceItemApiModel,
    identification?: PreviewProfileIdentificationModel,
    used_applications?: PreviewProfileApplication[]
}

interface UserCountAPI {
    users_count: number
}

const randomDelay = (maxwait: number) => {
    const delay = Math.round(Math.random() * maxwait);
    return new Promise(resolve => setTimeout(resolve, delay));
};

export const getUserByEmail = (accessToken: string, email: string): Promise<UserInfo> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(settings.myDanfossApi.endpoint + `/admin-actions/users/${email}?user_id_type=email`, {
        headers: requestHeaders,
        method: 'GET',
    });
}

export const getUserByIdentityId = (accessToken: string, identity_id: string, maxwait_override?: number): Promise<UserInfo> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    // Get max delay from settings unless maxwait_overide is specified
    const maxwait = maxwait_override || !isNaN(parseInt(settings.delayForEndpoints.read_users)) && parseInt(settings.delayForEndpoints.read_users) || 1000;
    return randomDelay(maxwait)
        .then(() => {
            return fetchApiWrapper(settings.myDanfossApi.endpoint + `/admin-actions/users/${identity_id}?user_id_type=danfoss_id`, {
                headers: requestHeaders,
                method: 'GET',
            });
        });
}

export const getUsersByEmail = (accessToken: string, emails: string[]): Promise<UserInfo[]> => {

    const users: UserInfo[] = [];
    return Promise.allSettled(emails.map(async (email: string) => {
        return getUserByEmail(accessToken, email);
    })).then((results) => {
        results.forEach(result => {
            if (result.status === "fulfilled" && result.value && result.value.email) {
                users.push(result.value);
            }
        })
        return Promise.resolve(users);
    })

}

export const getUsersByIdentityId = (accessToken: string, identity_ids: string[]): Promise<UserInfo[]> => {

    const users: UserInfo[] = [];
    const maxwait_per_user = !isNaN(parseInt(settings.delayForEndpoints.per_user_request)) && parseInt(settings.delayForEndpoints.per_user_request) || 100;
    // Calculate max delay based on number of requests to handle rate limit on user endpoint in Auth0
    return Promise.allSettled(identity_ids.map(async (identity_id: string) => {
        return getUserByIdentityId(accessToken, identity_id, identity_ids.length * maxwait_per_user);
    })).then((results) => {
        results.forEach(result => {
            if (result.status === "fulfilled" && result.value && result.value.danfoss_identity_id) {
                users.push(result.value);
            }
        })
        return Promise.resolve(users);
    })

}

export const getAuth0User = (userId: string, accessToken: string): Promise<UserModel> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    requestHeaders.append('Accept-Language', 'en')

    return fetchApiWrapper<UserModel>(`${settings.myDanfossAccountApi.endpoint}/users/${userId}`, {
        headers: requestHeaders,
        method: 'GET',
    });
}

export const getApplications = (userId: string, accessToken: string): Promise<UserApplications> => {
    return fetchApiWrapper(`${settings.myDanfossAccountApi.endpoint}/users/${userId}/applications`, {
        headers: {
            Authorization: `BEARER ${accessToken}`,
            'Content-Type': 'application/json-patch+json; charset=utf-8',
        },
        method: 'GET',
    });
}

export const getRecommendedApplicationsForUser = (accessToken: string): Promise<RecommendedApplications> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper<RecommendedApplications>(`${settings.myDanfossApi.endpoint}/users/me/recommended-applications`, {
        headers: requestHeaders,
        method: 'GET',
    });
}

export const getPrevievRecommendedApplications = (accessToken: string, profileId: string): Promise<PreviewRecommendedApplication[]> => {

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/previews/recommended-applications?danfoss_identity_id=${profileId}`, {
        headers: requestHeaders,
        method: 'GET',
    })
        .then((result: any) => {
            return result.recommended_apps.map((app: PreviewRecommendedApplication) => (
                {
                    published: app.status === 'Published',
                    client_id: app.client_id
                }
            ))
        })
}

export const getAllPreviewProfiles = (accessToken: string): Promise<PreviewProfileModel[]> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper<PreviewProfileAPI>(`${settings.myDanfossApi.endpoint}/previews/all-user-profiles`, {
        headers: requestHeaders,
        method: 'GET',
    })
        .then(result => {
            if (!(result instanceof Response)) {
                const user_profiles = result.user_profiles.map(up => { 
                    const areas_of_interest = up.areas_of_interest && up.areas_of_interest.length > 0 ? up.areas_of_interest : up.area_of_interest && [up.area_of_interest] || [];
                    return { ...up, areas_of_interest, area_of_interest: undefined};
                });
                return user_profiles;
            }
            else
                return ([])
        })
        .catch(() => {
            console.log('Error reading all preview profiles')
            return [];
        });
}

export const savePreviewProfile = (accessToken: string, profile?: PreviewProfileModel): Promise<PreviewProfileModel> => {
    if (!profile) {
        return Promise.reject();
    }

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper<PreviewProfileModel>(`${settings.myDanfossApi.endpoint}/previews/user-profiles`, {
        body: JSON.stringify(profile),
        headers: requestHeaders,
        method: 'PUT',
    })

}

export const deletePreviewProfile = (accessToken: string, profileId: string): Promise<Response> => {
    if (!profileId) {
        return Promise.reject();
    }

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper<Response>(`${settings.myDanfossApi.endpoint}/previews/user-profiles?danfoss_identity_id=${profileId}`, {
        headers: requestHeaders,
        method: 'DELETE',
    })

}

export const noOfUsersWithCriteria = (accessToken: string, query: string, noOfRecommAppsForUser?: number): Promise<number> => {
    if (!query) {
        return Promise.reject();
    }

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    
    // Get max delay from settings to handle rate limit on user endpoint in Auth0
    let maxwait = !isNaN(parseInt(settings.delayForEndpoints.read_users)) && parseInt(settings.delayForEndpoints.read_users) || 1000;
    if (noOfRecommAppsForUser) {
        const per_user_request = !isNaN(parseInt(settings.delayForEndpoints.per_user_request)) && parseInt(settings.delayForEndpoints.per_user_request) || 100;
        maxwait = per_user_request * noOfRecommAppsForUser * 3;
    }
    return randomDelay(maxwait)
        .then(() =>
            fetchApiWrapper<UserCountAPI>(`${settings.myDanfossAccountApi.endpoint}/users/count?query=${query}`, {
                headers: requestHeaders,
                method: 'GET',
            })
                .then(result => {
                    if (!(result instanceof Response))
                        return result.users_count;
                    else
                        return (0)
                })
                .catch(() => {
                    console.log('Error getting number of users for criteria')
                    return 0;
                }));



}
