import React, { useState, useEffect } from 'react';
import uniq from 'lodash/uniq';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { useLocation } from 'react-router-dom';
import { CheckboxWithName } from '../../RenderFields/RenderFields';
import { Field, reduxForm } from 'redux-form';
import { UnpublishLanguage, AppTranslationUnpublishProps } from './types';
import { useSelector } from 'react-redux';
import TranslationRow from './TranslationRow'
import { AppState } from '../../../Store';
import { Application, ApplicationWithTranslations, deleteApplicationTranslation, getApplicationsForUserWithRoles, getApplicationTranslations, LanguageTranslation } from '../../../API/application';
import { useAuth0 } from '@auth0/auth0-react';
import settings from '../../../Config/settings';
import { Roles } from '../../../Store/Roles/types';
import queryString from 'query-string';
import { Spinner } from '@danfoss/webex-ui';
import DataLoader from '../../DataLoader';
import Main from '../../Main';
import { languages } from '../../../Constants';
import { Button } from '@material-ui/core';
import ModalPopup from '../../Modal/ModalPopup';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { BreadCrumbs } from '../../BreadCrumbs/types';
import { allowPublishApplicationTexts, getDanfossIdentityId, getUserRoles } from '../../../Utils';
import { getContacts } from '../utils';

const AppTranslationUnpublish: React.FC<AppTranslationUnpublishProps> = ({ }) => {
    const classes = useStyles();
    const applications = useSelector((state: AppState) => state.applications && state.applications.applications);
    const applicationsLoaded = useSelector((state: AppState) => state.applications.applicationsLoadingStatus.loaded);
    const currentUser = useSelector((state: AppState) => state.user);
    const userRoles = useSelector((state: AppState) => getUserRoles(state.userRoles));
    const contacts = useSelector((state: AppState) => state.contacts.contactInfo);
    const [isLoading, setIsLoading] = useState(true);
    const [isUnpublishing, setIsUnpublishing] = useState(false);
    const [applicationsWithTranslation, setApplicationsWithTranslation] = useState<ApplicationWithTranslations[]>([]);
    const [selectedApplications, setSelectedApplications] = useState<string[]>([]);
    const [appVariantsVisible, setAppVariantsVisible] = useState<string[]>([]);
    const [selections, setSelections] = useState<UnpublishLanguage[]>([]);
    const [showConfirmUnpublish, setShowConfirmUnpublish] = useState(false);
    const [showCheckboxes, setShowCheckboxes] = useState(true);
    const [breadCrumbs, setBreadCrumbs] = useState({} as BreadCrumbs);
    const { getAccessTokenSilently } = useAuth0();
    const location = useLocation();
    //Get querystring parameters
    const parsed = queryString.parse(window.location.search);
    const client_id = parsed?.client_id as string;

    useEffect(() => {
        const getAppTranslations = async () => {
            if (!isUnpublishing && applicationsLoaded) {
                if (location.pathname === "/application/translation/overview") {
                    setShowCheckboxes(false);
                    setAppVariantsVisible([client_id]);
                    setBreadCrumbs({ items: [{ text: 'Application translation', link: `/application/translation?client_id=${client_id}` }, { text: 'Application translation overview', link: location.pathname }] })
                } else {
                    if (!userRoles.some(role => role === Roles.APPLICATION_OWNER || role === Roles.APPLICATION_PUBLISHER || role === Roles.DASHBOARD_ADMIN)) {
                        setShowCheckboxes(false);
                    }

                    setBreadCrumbs({ items: [(client_id ? { text: 'Application translation', link: `/application/translation?client_id=${client_id}` } : { text: 'publish overview', link: '/publish' }), { text: `Unpublish application translations`, link: location.pathname }] });
                }

                setIsLoading(true);

                if (client_id) {
                    const application = applications?.find(app => app.client_id == client_id) || {} as Application;
                    const appTranslations = await getApplicationTranslations(client_id);

                    setApplicationsWithTranslation([{ ...application, ...appTranslations }]);
                } else {
                    const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
                    const accessTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);

                    const getAppsForUser = async () => {
                        const isAdmin = userRoles.includes(Roles.DASHBOARD_ADMIN);
                        const clientIdsForUser: string[] = isAdmin
                            ? applications?.map(app => app.client_id) || []
                            : await getApplicationsForUserWithRoles(
                                accessTokenMyDanfossApi,
                                accessTokenMyDanfossAccountApi,
                                getDanfossIdentityId(currentUser),
                                [Roles.APPLICATION_OWNER, Roles.APPLICATION_PUBLISHER]
                            );
    
                        return applications?.filter(app => clientIdsForUser.includes(app.client_id)) || [];
                    }
    
                    const appsForUser = await getAppsForUser();

                    const getAppsWithTranslationsTasks = appsForUser.map(async app => {
                        const appTranslations = await getApplicationTranslations(app.client_id);
    
                        return { ...app, ...appTranslations } as ApplicationWithTranslations;
                    });

                    const appsWithTranslations = await Promise.all(getAppsWithTranslationsTasks);

                    setApplicationsWithTranslation(appsWithTranslations);
                }

                setIsLoading(false);
            }
        }

        getAppTranslations();
    }, [applications, isUnpublishing]);

    useEffect(() => {
        const applicationClientIds = uniq(selections.map(l => l.appLanguage.substring(0, l.appLanguage.indexOf("#"))));

        setSelectedApplications(applicationClientIds);
    }, [selections]);


    const handleCheckboxClick = (target: any) => {
        if (!allowPublish()) {
            return;
        }

        if (target.checked) {
            if (!selections.find((item: UnpublishLanguage) => item.appLanguage === target.id)) {
                setSelections([...selections, { appLanguage: target.id }]);
            }
        } else {
            setSelections(selections.filter(item => item.appLanguage !== target.id));
        }
    }

    const onChangeSelectAllLanguages = (clientId: string, checked: boolean) => {
        if (!allowPublish()) {
            return;
        }

        if (checked) {
            const translations = applicationsWithTranslation.find(app => app.client_id === clientId)?.application_texts || [];
            let selectionsToAdd = selections;

            translations.filter(trans => trans.published).forEach(trans => {
                const selection = { appLanguage: `${clientId}#${trans.language}` };

                if (!selections.find((item: UnpublishLanguage) => item.appLanguage === selection.appLanguage)) {
                    selectionsToAdd.push(selection);
                    setSelections([...selectionsToAdd]);
                }
            })
        } else {
            setSelections(selections.filter(selection => !selection.appLanguage.startsWith(`${clientId}#`)));
        }
    }

    const unpublishSelectedTranslations = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsUnpublishing(true);

        const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

        await Promise.allSettled(
            selections.map(selection => {
                const [selClientId, selLang] = selection.appLanguage.split('#');
                return deleteApplicationTranslation(selClientId, selLang, false, accessTokenMyDanfossApi)
            })
        )

        setShowConfirmUnpublish(false);
        setIsUnpublishing(false);
        setSelections([]);

    }

    const onChangeSelectApp = (clientId: string, checked: boolean) => {
        if (!allowPublish()) {
            return;
        }

        onChangeSelectAllLanguages(clientId, checked);
    }

    const toggleVariants = (id: string) => {
        if (!appVariantsVisible.includes(id)) {
            setAppVariantsVisible([...appVariantsVisible, id]);
        } else {
            setAppVariantsVisible(appVariantsVisible.filter((variant: string) => variant !== id));
        }

        return;
    }

    const getTranslationsRows = () => {
        let translationsRows: ApplicationWithTranslations[] = [] as ApplicationWithTranslations[];

        if (location.pathname === "/application/translation/overview") {
            // Add drafts
            (applicationsWithTranslation.filter(awt => awt.application_texts.filter(appText => !appText.published)).forEach(appWitTrans => {
                translationsRows.push(appWitTrans);
            }));

            // Add published if no draft
            (applicationsWithTranslation.filter(awt => awt.application_texts.filter(appText => appText.published)).forEach(appWitTrans => {
                if (!translationsRows.some(transRow => transRow.client_id === appWitTrans.client_id)) {
                    translationsRows.push(appWitTrans);
                }
            }));

            return translationsRows;

        } else {
            return applicationsWithTranslation.filter(awt => awt.application_texts.filter(appText => appText.published).length > 0)
        }
    }

    const getTranslationTexts = (application_texts: LanguageTranslation[]) => {
        if (location.pathname === "/application/translation/overview") {

            const appTexts = [] as LanguageTranslation[];

            // Add drafts
            application_texts.filter(t => !t.published).forEach(texts => {
                appTexts.push(texts);
            });

            // Add published if no draft
            application_texts.filter(t => t.published).forEach(texts => {
                if (!appTexts.some(applicationTexts => applicationTexts.language === texts.language)) {
                    appTexts.push(texts);
                }
            });

            return appTexts;
        } else {
            return application_texts.filter(appTexts => appTexts.published);
        }
    }

    const getRowStatus = (app: ApplicationWithTranslations, appTexts: LanguageTranslation) => {
        const hasPublishedVersion = (app.application_texts.filter(texts => texts.language === appTexts.language && texts.published)).length > 0;
        const hasDraftVersion = (app.application_texts.filter(texts => texts.language === appTexts.language && !texts.published)).length > 0;

        if (hasPublishedVersion && hasDraftVersion) {
            return "Under change";
        } else if (hasDraftVersion && !hasPublishedVersion) {
            return "New";
        } else {
            return "Published"
        }
    }

    const getApplicationStatus = (clientId: string) => {
        const hasPublishedVersion = (applicationsWithTranslation?.find(app => app.client_id === clientId)?.application_texts.filter(texts => texts.published) || []).length > 0;
        const hasDraftVersion = (applicationsWithTranslation?.find(app => app.client_id === clientId)?.application_texts.filter(texts => !texts.published) || []).length > 0

        if (hasPublishedVersion && hasDraftVersion) {
            return "Under change";
        } else if (hasDraftVersion && !hasPublishedVersion) {
            return "New";
        } else {
            return "Published"
        }
    }

    if (showConfirmUnpublish) {
        (document.getElementById("mainContent") || {} as HTMLElement).scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const allowPublish = () => {
        return allowPublishApplicationTexts(userRoles);
    }

    const appsWithTranslationToShow = getTranslationsRows();

    const onChangeSelectAll = (checked: boolean) => {
        if (!allowPublish()) {
            return;
        }

        if (checked) {
            appsWithTranslationToShow.forEach(app => {
                onChangeSelectAllLanguages(app.client_id, checked);
            });
        } else {
            setSelections([]);
        }
    }

    return isLoading ? (<Spinner visible={isLoading} />) : (
        <Main breadCrumbs={breadCrumbs}>
            {showConfirmUnpublish &&
                <Grid container className={classes.confirmPopupContainer}>
                    <ModalPopup paperClassName={classes.confirmPopup}>
                        <div className={classes.bannerContainer}>
                            <Grid container alignItems="flex-start" direction="row">
                                <Grid className={classes.confirmPopupHeader} >
                                    Remove application translations
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" justifyContent="flex-start" direction="row">
                                Do you really want to remove application translations for these languages?
                            </Grid>
                            <Grid container alignItems="flex-start" direction="row" className={classes.appsContainer}>
                                {selectedApplications.map((selectedApp, idx) => {

                                    const appName = applications?.find(app => app.client_id === selectedApp)?.name || '';

                                    return (
                                        <Grid container key={`warning_${selectedApp}`}>
                                            <Grid container>
                                                {appName}
                                            </Grid>
                                            <Grid container>
                                                <ul>
                                                    {selections.filter(sel => sel.appLanguage.substring(0, sel.appLanguage.indexOf("#")) === selectedApp).map((selection, idx) => {

                                                        const language = languages.find(lan => lan.id === selection.appLanguage.replace(`${selectedApp}#`, ''));

                                                        return <li key={idx}>{language?.text}</li>
                                                    })}
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <Grid container alignItems="flex-start" direction="row" className={classes.confirmButtons}>
                                <Grid item>
                                    <SpinnerButton
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        pathToImagesFolder={'/images/icons'}
                                        spinnerVisible={isUnpublishing}
                                        className={classes.buttonLeft}
                                        onClick={(e: any) => unpublishSelectedTranslations(e)}
                                    >
                                        Yes, remove them
                                    </SpinnerButton>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="default"
                                        onClick={() => {
                                            setSelections([]);
                                            setShowConfirmUnpublish(false);
                                        }}
                                        className={classes.button}
                                    >
                                        No, leave them
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </ModalPopup>
                </Grid>
            }
            <DataLoader applicationsRequired={true} contactsRequired={true} >

                {!allowPublish() &&
                    <Grid container className={classes.notAllowed}>
                        {`You are not allowed to unpublish yourself - please ask ${getContacts(contacts)} to unpublish the application translations`}
                    </Grid>
                }

                <form id="appTranslationunPublish" className={classes.translationForm}>
                    <Grid container className={classes.listGridHeading}>
                        <Grid item className={`${classes.listGridItem} ${classes.listGridSelect}`} >
                            {showCheckboxes ? (
                                <Field
                                    name="selectAll"
                                    id="selectAll"
                                    value={"Application name"}
                                    defaultValue={"Application name"}
                                    checked={allowPublish() && (selectedApplications.length > 0 &&
                                        appsWithTranslationToShow.every(web => selectedApplications.some(clientId => clientId === web.client_id)))}
                                    component={CheckboxWithName}
                                    onClick={(event: any) => { onChangeSelectAll(event.target.checked); }}
                                    label=""
                                />
                            ) : (
                                <span className={classes.onlyLabelBold}>{"Application name"}</span>
                            )}
                        </Grid>
                        <Grid item className={`${classes.listGridItem} ${classes.listGridStatus}`} >
                            Status
                        </Grid>
                    </Grid>
                    {appsWithTranslationToShow.map((app, idx) => {

                        return (
                            <Grid container key={app.client_id}>
                                <Grid key={idx} container className={`${classes.listGrid} ${idx % 2 === 0 ? classes.listGridEven : classes.listGridOdd}`} >
                                    <Grid item className={`${classes.listGridItem} ${classes.listGridSelect}`} >
                                        {showCheckboxes ? (
                                            <Field
                                                name={`publish${idx}`}
                                                id={`publish${idx}`}
                                                value={app.name}
                                                defaultValue={app.name}
                                                component={CheckboxWithName}
                                                onClick={(event: any) => { onChangeSelectApp(app.client_id, event.target.checked); }}
                                                checked={allowPublish() && selectedApplications.some(selApp => selApp === app.client_id)}
                                                label=""
                                                className={classes.checkboxLabel}
                                            />
                                        ) : (
                                            <span className={classes.onlyLabelBold}>{app.name}</span>
                                        )}
                                    </Grid>
                                    <Grid item className={`${classes.listGridItem} ${classes.listGridStatus}`} onClick={() => toggleVariants(app.client_id)}>
                                        {!showCheckboxes ? getApplicationStatus(app.client_id) : ""}
                                    </Grid>
                                    <Grid item className={`${classes.listGridItem} ${classes.listGridIcon}`} onClick={() => toggleVariants(app.client_id)}>
                                        <img src={'/images/icons/ChevronDown.svg'} className={appVariantsVisible.includes(app.client_id) ? classes.iconStyleRotate : classes.iconStyle} alt="" />
                                    </Grid>
                                </Grid>
                                <Grid container className={appVariantsVisible.includes(app.client_id) ? classes.show : classes.hide}>
                                    <Grid container className={classes.subListGridHeading}>
                                        <Grid item className={`${classes.listGridItem} ${classes.listGridSelectLanguage}`} >
                                            {showCheckboxes ? (
                                                <Field
                                                    name="selectAll"
                                                    id="selectAll"
                                                    value={"Language"}
                                                    defaultValue={"Language"}
                                                    checked={(app.application_texts?.length > 0 &&
                                                        app.application_texts.filter(apptext => apptext.published).every(trans => selections.some(sel => sel.appLanguage === `${app.client_id}#${trans.language}`)))}
                                                    component={CheckboxWithName}
                                                    onClick={(event: any) => { onChangeSelectAllLanguages(app.client_id, event.target.checked); }}
                                                    label=""
                                                />
                                            ) : (
                                                <span className={classes.onlyLabelBold}>{"Language"}</span>
                                            )}
                                        </Grid>
                                        <Grid item className={`${classes.listGridItem} ${classes.listGridName}`} >
                                            Name
                                        </Grid>
                                        <Grid item className={`${classes.listGridItem} ${classes.listGridName} ${!showCheckboxes ? classes.show : classes.hide}`} >
                                            Status
                                        </Grid>

                                    </Grid>
                                    {getTranslationTexts(app.application_texts).map((appTexts, index) => {

                                        const isChecked = selections.some(appLan => appLan.appLanguage === `${app.client_id}#${appTexts.language}`) || false;
                                        const useGrayBackground = index % 2 === 0 ? false : true;
                                        const language = languages.find(lan => lan.id === appTexts.language);

                                        return (<TranslationRow clientId={app.client_id} useGrayBackground={useGrayBackground} onChange={handleCheckboxClick} languageTranslation={appTexts} language={language?.text || ''} key={index} checked={isChecked} rowContainerClass={classes.rowContainer} showCheckbox={showCheckboxes !== undefined ? showCheckboxes : true} status={getRowStatus(app, appTexts)} />)
                                    })}
                                </Grid>

                            </Grid>
                        )
                    })}
                    <Grid container >
                        <Grid item className={classes.buttonContainer}>
                            {!isLoading && allowPublish() && selections.length > 0 &&
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setShowConfirmUnpublish(true)}
                                >
                                    Unpublish selected translations
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            </DataLoader>
        </Main >
    );
}

export default reduxForm({
    form: 'appTranslationunPublish'
})(AppTranslationUnpublish);
