import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	bannerContainer: {
		position: 'relative'
	},
	toggleButtonContainer: {
		marginBottom: '20px',
		justifyContent: 'end'
	},
	applicationListGridHeading: {
		backgroundColor: colors.webexuiGraySoft,
		minHeight: '40px',
		fontWeight: 600
	},
	notAllowed: {
		minHeight: '40px',
		fontWeight: 600,
		color: colors.webexuiRedStrong,
		fontSize: '20px'
	},
	applicationListGridItem: {
		padding: '5px 8px 5px 8px',
		marginTop: '10px'
	},
	applicationListGridSelect: {
		width: '5%',
		marginTop: '0px'
	},
	applicationListGridName: {
		width: '20%'
	},
	applicationListGridCriteria: {
		width: '45%',
		marginBottom: '12px'
	},
	applicationListGridStatus: {
		width: '15%',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	applicationListGridPublishButton: {
		paddingLeft: '0px'
	},
	applicationListGrid: {
		minHeight: '40px'
	},
	applicationListGridOdd: {
		backgroundColor: colors.webexuiGraySoft
	},
	applicationListGridEven: {
		backgroundColor: colors.webexuiWhite
	},
	button: {
		marginRight: '20px'
	},
	navigationButton: {
		marginLeft: '20px'
	},
	iconStyle: {
		top: '0',
		right: '0',
		display: 'inline-block',
		color: '#c0c0c0',
		width: '13px',
		height: '13px',
		margin: '12px 10px 12px 0px',
		verticalAlign: 'middle',
		pointerEvents: 'none'
	},
	expandText: {
		verticalAlign: 'middle'
	},
	iconStyleRotate: {
		transform: 'rotate(180deg)'
	},
	criteriaIcon: {
		cursor: 'pointer'
	},
	criteriaForm: {
		width: '100%'
	},
	confirm: {
		boxShadow: '0px -4px 0px #E2000F',
		borderRadius: '0px 0px 2px 2px',
		"&:hover": {
			boxShadow: '0px -4px 0px #E2000F, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
		}
	},
	confirmButtons: {
		marginTop: '20px'
	},
	confirmPopup: {
		position: 'absolute',
		margin: '0 auto',
		maxWidth: '546px',
		[theme.breakpoints.up('sm')]: {
			margin: '0px 15px 0'
		},
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 35px 50px 35px !important'
	},
	confirmPopupContainer: {
		maxWidth: '1280px',
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		top: '250px'
	},
	confirmPopupHeader: {
		fontSize: '24px',
		fontWeight: 'bold',
		margin: '20px 0 16px'
	}

}));