import AppTranslation from '../Application/Translation/';
import AppCriteria from '../Application/Criteria/';
import AppSetup from '../Application/Setup'; 
import PublishOverview from '../Publish';
import AppTranslationPublish from '../Publish/ApplicationTranslation';
import AppTranslationUnpublish from '../Publish/ApplicationTranslation/AppTranslationUnpublish';
import AppCriteriaPublish from '../Publish/ApplicationCriteria';
import AppCriteriaUnpublish from '../Publish/ApplicationCriteria/AppCriteriaUnpublish';
import WebsitePublish from '../Publish/Website';
import WebsiteUnpublish from '../Publish/Website/WebsiteUnpublish';
import WebsiteOverview from '../Website';
import PreviewOverview from '../Preview';
import AppCriteriaPreview from '../Preview/ApplicationCriteria';
import PreviewWebsite from '../Preview/Website';
import Website from '../Website/Website';
import UndoWebsiteChanges from '../Website/UndoWebsiteChanges';
import UndoApplicationChanges from '../Application/Translation/UndoApplicationChanges';
import Download from '../Download'
import UserRoles from '../UserRoles';
import AppOwners from '../UserRoles/AppOwners';
import AppRoles from '../UserRoles/AppRoles';
import Home from '../Home';
import Login from '../Login';
import { ThemeProvider } from '@material-ui/styles';
import theme from "./MuiTheme";
import LoginError from '../LoginError';
import Logout from '../Logout';
import NoAccess from '../NoAccess';
import Impersonate from '../Impersonate';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import ProtectedRoute from '../ProtectedRoute';
import React from 'react';
import { Roles } from '../../Store/Roles/types';
import ClickOutsideWrapper from '../ClickOutsideWrapper';
import ContactInformation from '../ContactInformation';

function App() {
  return (
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<ProtectedRoute allowedRoles={[]}><Home/></ProtectedRoute>} />
            <Route path="/login"  element={<Login/>} />
            <Route path="/loginerror"  element={<LoginError/>} />
            <Route path="/logout"  element={<Logout/>} />
            <Route path="/noaccess"  element={<NoAccess/>} />
            <Route path="/application/translation/overview"  element={<ProtectedRoute allowedRoles={[Roles.APPLICATION_OWNER, Roles.APPLICATION_EDITOR, Roles.APPLICATION_PUBLISHER, Roles.DASHBOARD_ADMIN]}><AppTranslationUnpublish/></ProtectedRoute> } />
            <Route path="/application/translation" element={<ProtectedRoute allowedRoles={[Roles.APPLICATION_EDITOR, Roles.APPLICATION_OWNER, Roles.APPLICATION_PUBLISHER, Roles.DASHBOARD_ADMIN]}><ClickOutsideWrapper><AppTranslation/></ClickOutsideWrapper></ProtectedRoute>} />
            <Route path="/application/criteria" element={<ProtectedRoute allowedRoles={[Roles.RECOMMENDED_APPLICATION_EDITOR, Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN]}><AppCriteria/></ProtectedRoute>} />
            <Route path="/application/setup"  element={<ProtectedRoute allowedRoles={[Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN, Roles.APPLICATION_SETUP_VIEWER]}><AppSetup/></ProtectedRoute> }/>
            <Route path="/application/undochanges" element={<ProtectedRoute allowedRoles={[Roles.RELEVANT_WEBSITE_EDITOR, Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN]}><UndoApplicationChanges/></ProtectedRoute> }/>
            <Route path="/publish/applicationtranslation/unpublish" element={<ProtectedRoute allowedRoles={[Roles.APPLICATION_OWNER, Roles.APPLICATION_PUBLISHER, Roles.DASHBOARD_ADMIN]}><AppTranslationUnpublish/></ProtectedRoute> }/>
            <Route path="/publish/applicationtranslation" element={<ProtectedRoute allowedRoles={[Roles.APPLICATION_OWNER, Roles.APPLICATION_PUBLISHER, Roles.DASHBOARD_ADMIN]}><AppTranslationPublish/></ProtectedRoute> }/>
            <Route path="/publish/applicationcriteria/unpublish" element={<ProtectedRoute allowedRoles={[Roles.APPLICATION_OWNER, Roles.RECOMMENDED_APPLICATION_PUBLISHER, Roles.DASHBOARD_ADMIN]}><AppCriteriaUnpublish/></ProtectedRoute> }/>
            <Route path="/publish/applicationcriteria" element={<ProtectedRoute allowedRoles={[Roles.APPLICATION_OWNER, Roles.RECOMMENDED_APPLICATION_PUBLISHER, Roles.DASHBOARD_ADMIN]}><AppCriteriaPublish/></ProtectedRoute> }/>
            <Route path="/publish/website/unpublish" element={<ProtectedRoute allowedRoles={[Roles.DASHBOARD_ADMIN, Roles.RELEVANT_WEBSITE_PUBLISHER]}><WebsiteUnpublish/></ProtectedRoute> }/>
            <Route path="/publish/website" element={<ProtectedRoute allowedRoles={[Roles.DASHBOARD_ADMIN, Roles.RELEVANT_WEBSITE_PUBLISHER]}><WebsitePublish/></ProtectedRoute> }/>
            <Route path="/publish" element={<ProtectedRoute allowedRoles={[Roles.APPLICATION_OWNER, Roles.APPLICATION_PUBLISHER, Roles.RELEVANT_WEBSITE_PUBLISHER, Roles.RECOMMENDED_APPLICATION_PUBLISHER, Roles.DASHBOARD_ADMIN]}><PublishOverview/></ProtectedRoute> }/>
            <Route path="/preview/applicationcriteria" element={<ProtectedRoute allowedRoles={[]}><AppCriteriaPreview/></ProtectedRoute> }/>
            <Route path="/preview/websites" element={<ProtectedRoute allowedRoles={[]}><PreviewWebsite/></ProtectedRoute> }/>
            <Route path="/preview" element={<ProtectedRoute allowedRoles={[]}><PreviewOverview/></ProtectedRoute> }/>
            <Route path="/websiteoverview" element={<ProtectedRoute allowedRoles={[Roles.RELEVANT_WEBSITE_EDITOR, Roles.DASHBOARD_ADMIN]}><WebsiteOverview/></ProtectedRoute> }/>
            <Route path="/website/undochanges" element={<ProtectedRoute allowedRoles={[Roles.RELEVANT_WEBSITE_EDITOR, Roles.DASHBOARD_ADMIN]}><UndoWebsiteChanges/></ProtectedRoute> }/>
            <Route path="/website/overview" element={<ProtectedRoute allowedRoles={[Roles.RELEVANT_WEBSITE_EDITOR, Roles.DASHBOARD_ADMIN]}><WebsiteUnpublish/></ProtectedRoute> }/>
            <Route path="/website" element={<ProtectedRoute allowedRoles={[Roles.RELEVANT_WEBSITE_EDITOR, Roles.DASHBOARD_ADMIN]}><ClickOutsideWrapper><Website/></ClickOutsideWrapper></ProtectedRoute> }/>
            <Route path="/userroles/appowners" element={<ProtectedRoute allowedRoles={[Roles.DASHBOARD_ADMIN]}><ClickOutsideWrapper><AppOwners/></ClickOutsideWrapper></ProtectedRoute>  }/>
            <Route path="/listappowners" element={<AppOwners/>}/>
            <Route path="/userroles/approles" element={<ProtectedRoute allowedRoles={[Roles.DASHBOARD_ADMIN, Roles.APPLICATION_OWNER]}><ClickOutsideWrapper><AppRoles/></ClickOutsideWrapper></ProtectedRoute> }/>
            <Route path="/userroles"  element={<ProtectedRoute allowedRoles={[Roles.APPLICATION_OWNER, Roles.DASHBOARD_ADMIN]}><UserRoles/></ProtectedRoute> }/>
            <Route path="/impersonate"  element={<ProtectedRoute allowedRoles={[Roles.DASHBOARD_ADMIN]}><Impersonate/></ProtectedRoute> }/>
            <Route path="/contactinfo"  element={<ContactInformation/>}/>
          </Routes>
        </Router>
      </ThemeProvider>
  );
}

export default App;
