import React, { useEffect, useState } from 'react';
import Main from '../Main/Main';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Field, FormErrors, InjectedFormProps, reduxForm, stopAsyncValidation } from 'redux-form';
import { LabeledTextboxUncontrolled } from '../RenderFields/RenderFields';
import { useStyles } from './styles';
import { isValidEmail, asyncValidate, validate } from './validation';
import { getUserByEmail, UserInfo } from '../../API/users';
import { getIdpDomainConnections } from '../../API/connection';
import settings from '../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import { SpinnerButton } from '@danfoss/webex-ui/dist/mui';
import { clearImpersonatedUser, setImpersonatedUser } from '../../Store/User/actions';
import { AppState } from '../../Store';
import { ApplicationsUserRoles, getRolesForUser, getUsersRolesPerApplication } from '../../API/roles';
import DataLoader from '../DataLoader';
import { getApplicationsForOwner } from '../../API/application';
import { getRolesEnum, Roles } from '../../Store/Roles/types';
import { clearRolesForImpersonatedUser, setRolesForImpersonatedUser } from '../../Store/Roles/actions';
import queryString from 'query-string';
import AccessRights from '../AccessRights';

const FORMID = 'impersonate';
const Impersonate: React.FC<InjectedFormProps<{}>> = (props) => {
    const { handleSubmit, change } = props;
    const location = useLocation();
    const classes = useStyles();
    const [onChangeTimeout, setOnChangeTimeout] = useState(undefined) as any;
    const [idpDomains, setIdpDomains] = useState([] as string[]);
    const [accessTokenMyDanfossApi, setAccessTokenMyDanfossApi] = useState('');
    const [accessTokenMyDanfossAccountApi, setAccessTokenMyDanfossAccountApi] = useState('');
    const [validUser, setValidUser] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedUser, setSelectedUser] = useState(undefined as UserInfo | undefined );
    const impersonatedUser = useSelector((state: AppState) => state.user.impersonatedUser);
    const impersonatedUserRoles = useSelector((state: AppState) => state.userRoles.impersonatedUserRoles);
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    interface ApplicationsPerRole {
        role: string,
        applications: string[]
    }

    const validateDanfossEmail = async (email: string, accessTokenForMyDanfossApi: string ) => {
        try {
            const user = await getUserByEmail(accessTokenForMyDanfossApi, email);

            setValidUser(true);
            setSelectedUser(user);
            updateReadOnlyFields(user.danfoss_identity_id, user.name, user.phone_number);
        }
        catch {
            const errors: FormErrors<any, any> = {};
            errors.email = 'Email not found';
            clearReadOnlyFields();
            setValidUser(false);
            dispatch(stopAsyncValidation(FORMID, errors));
        }
    }

    const clearReadOnlyFields = () => {
        updateReadOnlyFields('', '', '');
    }

    const updateReadOnlyFields = (danfoss_identity_id: string, name: string, phone_number: string) => {
        change(`danfoss_identity_id`, danfoss_identity_id);
        change(`name`, name);
        change(`phone_number`, phone_number);
    }


    const onChangeEmail = async (event: any) => {
        if (onChangeTimeout) {
            // Cancel pending validation of Danfoss email from previous onChange
            clearTimeout(onChangeTimeout);
        }

        let timeoutId: any = undefined;
        if (isValidEmail(event.target.value, {}, { idpDomains })) {
            // Delay testing of Danfoss email to avoid too many calls to the API during typíng of the email
            timeoutId = setTimeout(async () => await validateDanfossEmail(event.target.value, accessTokenMyDanfossApi), 2000);
        }
        else {
            const errors: FormErrors<any, any> = {};
            errors.email = 'Please enter a valid email';
            dispatch(stopAsyncValidation(FORMID, errors));
        }

        clearReadOnlyFields();
        setValidUser(false);
        setOnChangeTimeout(timeoutId);
    }

    const onBlurEmail = async (event: any) => {
        if (onChangeTimeout) {
            clearTimeout(onChangeTimeout);
            setOnChangeTimeout(undefined);
        }

        if (isValidEmail(event.target.value, {}, { idpDomains })) {
            await validateDanfossEmail(event.target.value, accessTokenMyDanfossApi);
        }
        else {
            clearReadOnlyFields();
        }
    }

    const submitForm = (values: any) => {
        const { email, name, phone_number, danfoss_identity_id } = values;
        setIsSaving(true);
        dispatch(setImpersonatedUser({ email, name, phone_number, danfoss_identity_id }));
        setIsSaving(false)
    }

    const stopImpersonatingUser = () => {
        dispatch(clearImpersonatedUser());
        dispatch(clearRolesForImpersonatedUser());
    }

    useEffect(() => {
        const init = async () => {
            const idpConnections = await getIdpDomainConnections();
            const idpDomains = idpConnections.filter(conn => conn.connection_name === settings.auth0.connection).map(conn => (conn.domains))
            setIdpDomains(idpDomains && idpDomains.length > 0 && idpDomains[0] || []);
            change(`idpDomains`, idpDomains[0]);
            const accessForTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
            const accessForTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
            setAccessTokenMyDanfossApi(accessForTokenMyDanfossApi);
            setAccessTokenMyDanfossAccountApi(accessForTokenMyDanfossAccountApi);
            if (impersonatedUser) {
                const [applicationsForOwnerResponse, userRolesPerApplicationResponse] = await Promise.all([
                    getApplicationsForOwner(impersonatedUser.danfoss_identity_id, accessForTokenMyDanfossAccountApi),
                    getUsersRolesPerApplication(impersonatedUser.danfoss_identity_id, accessForTokenMyDanfossApi)
                ]);
            }
            else {
                const parsed = queryString.parse(location.search);
                const queryemail = (parsed && parsed.email) as string;
                if (queryemail) {
                    change('email', queryemail);
                    await validateDanfossEmail(queryemail, accessForTokenMyDanfossApi);
                }
            }
        };

        init();

    }, []);

    useEffect(() => {
        const getImpersonatedUserRoles = async () => {
            if (impersonatedUser && !impersonatedUserRoles) {
                let [userRoles, applicationsForOwner] = await Promise.all([
                    getRolesForUser(impersonatedUser.danfoss_identity_id, accessTokenMyDanfossApi),
                    getApplicationsForOwner(impersonatedUser.danfoss_identity_id, accessTokenMyDanfossAccountApi)
                ]);

                const roles: Roles[] = userRoles.roles
                    .map(role => (getRolesEnum(role)))
                    .filter(role => role !== Roles.NONE);
                if (applicationsForOwner.length > 0) {
                    roles.push(Roles.APPLICATION_OWNER);
                }
                dispatch(setRolesForImpersonatedUser(roles.filter(role => role !== Roles.DASHBOARD_ADMIN)));
            }
        };

        getImpersonatedUserRoles();

    }, [impersonatedUser, impersonatedUserRoles]);

    return (
        <Main breadCrumbs={{ items: [{ text: `Impersonate user`, link: location.pathname }] }}>
			<DataLoader applicationsRequired={true} >
            <Grid container>
                <Grid item xs={12} className={classes.explanation}>
                    When you impersonate a user you will see the tiles, applications and authorizations as the user will see it. <br/>
                    Any changes you make while impersonating a user will be registered in you own name - not the impersonated user.
                </Grid>
                {impersonatedUser?.email &&
                    <React.Fragment>
                        <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row" >
                            <Grid item xs={12}>
                                You are currently impersonating: <span style={{ fontWeight: 'bold' }}>{impersonatedUser.email} {impersonatedUser.name}</span>
                            </Grid>
                        </Grid>
                        <Grid container id="buttonContainer" className={classes.buttonContainer}>
                            <SpinnerButton
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={() => { stopImpersonatingUser() }}
                                className={classes.button}
                                pathToImagesFolder={'/images/icons'}
                                spinnerVisible={false}
                            >
                                Stop impersonation?
                            </SpinnerButton>
                        </Grid>
                    </React.Fragment>
                    ||
                    <Grid container className={classes.formContainer}>
                        <form onSubmit={handleSubmit(submitForm)} id={FORMID}>
                            <Field
                                name={`email`}
                                label='Email'
                                component={LabeledTextboxUncontrolled}
                                onBlur={(event: any) => { onBlurEmail(event) }}
                                onChange={(event: any) => { onChangeEmail(event) }}
                                InputProps={{
                                    classes: {
                                        input: classes.blackText
                                    }
                                }}
                            />
                            <Field
                                name={`name`}
                                label='Name'
                                component={LabeledTextboxUncontrolled}
                                disabled={true}
                                InputProps={{
                                    classes: {
                                        input: classes.blackText
                                    }
                                }}
                            />
                            <Field
                                name={`phone_number`}
                                label='Phone number'
                                component={LabeledTextboxUncontrolled}
                                disabled={true}
                                InputProps={{
                                    classes: {
                                        input: classes.blackText
                                    }
                                }}
                            />
                            <Grid container id="buttonContainer" className={classes.buttonContainer}>
                                <SpinnerButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    pathToImagesFolder={'/images/icons'}
                                    spinnerVisible={isSaving}
                                    disabled={!validUser}
                                >
                                    Impersonate this user
                                </SpinnerButton>
                            </Grid>
                        </form>
                    </Grid>
                }
                {!impersonatedUser && validUser && selectedUser &&
                    <AccessRights danfoss_identity_id={selectedUser.danfoss_identity_id} heading={`Access rights for ${selectedUser?.name || ''} `} />
                }
                {impersonatedUser &&
                    <AccessRights danfoss_identity_id={impersonatedUser.danfoss_identity_id} heading={`Access rights for ${impersonatedUser?.name || ''} `} />
                }
            </Grid >
            </DataLoader>
        </Main >
    );
}

const ImpersonateReduxForm = reduxForm({
    form: FORMID,
    validate,
    asyncValidate,
    asyncBlurFields: ['email'],
    asyncChangeFields: [],
    shouldAsyncValidate: () => false
})(Impersonate)

export default connect()(ImpersonateReduxForm);
