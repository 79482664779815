import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from '@danfoss/webex-ui';
import UndoChanges from '../../UndoChanges/UndoChanges';
import queryString from 'query-string';
import { ListItem, UndoLanguage } from '../../UndoChanges/types';
import { Application, deleteApplicationTranslation, getApplicationTranslations } from '../../../API/application';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import DataLoader from '../../DataLoader';
import { useAuth0 } from '@auth0/auth0-react';
import settings from '../../../Config/settings';

const UndoApplicationChanges: React.FC = () => {
    const { getAccessTokenSilently } = useAuth0();
    const applications = useSelector((state: AppState) => state.applications && state.applications.applications);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [listItem, setListItem] = useState({} as ListItem)
    //Get querystring parameters
    const parsed = queryString.parse(window.location.search);
    const client_id = (parsed && parsed.id) as string

    useEffect(() => {
        
        const application = applications?.find(app => app.client_id == client_id) || {} as Application;

        const getAppTranslations = async () => {
            const appTranslations = await getApplicationTranslations(client_id);

            // Draft versions found, now convert to UndoChangeItems
            const data = appTranslations.application_texts.filter(trans => !trans.published).map(trans => {
                return { language: trans.language, texts: [{name: 'Name', text: trans.name}, {name: 'Description', text: trans.description}, {name: 'Login text', text: trans.login_text}, {name: 'Signup', text: trans.signup_text}] };
            });

            setListItem({ id: client_id, name: application.name, itemHeaders: ['Name'], data: data, type:"app" });

            
        }

        getAppTranslations().then(resp => {
            setIsLoading(false)
        });

    }, [applications]);


    const breadCrumbs = { items: [{ text: `Application translation`, link: `/application/translation?client_id=${client_id}` }, { text: 'view changes', link: location.pathname }] }

    const undoChanges = async (selections: UndoLanguage[], setIsUndoing: Function) => {

        setIsUndoing(true);
        
        let accessTokenMyDanfossApi = "";

        const getAccessToken = async () => {
            accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
        }

        getAccessToken().then(resp => {

            selections.forEach(selection => {
                const deleteApplicationDraftTexts = async () => {
                
                    const firstUnderscore = selection.language.indexOf('_');
                    const client_id = selection.language.substring(0, firstUnderscore)
                    const language = selection.language.substring(firstUnderscore + 1, selection.language.length-1);
                    
                    await deleteApplicationTranslation(client_id, language, true, accessTokenMyDanfossApi)
                }
    
                deleteApplicationDraftTexts();
            })

        });
        
        setIsUndoing(false);
    }

    return isLoading ? <Spinner visible={isLoading} ></Spinner> : (
        <DataLoader applicationsRequired={true} >
            <UndoChanges
                breadCrumbs={breadCrumbs}
                typeName='Application'
                handleSubmitClick={undoChanges}
                listItem={listItem}
            />
        </DataLoader>
    );
}

export default UndoApplicationChanges;
